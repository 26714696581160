<template>
  <div id="PreviewImage">
    <div class="toolBox">
      <i class="fas fa-plus ml-2 ZoomIn" @click="croppa.zoomIn()"></i>
      <i class="fas fa-minus ml-2 ZoomOut" @click="croppa.zoomOut()"></i>
      <i class="fas fa-undo ml-2 RotateBack" @click="croppa.rotate(-1)"></i>
      <i class="fas fa-redo ml-2 RotateNext" @click="croppa.rotate(1)"></i>
      <v-btn class="ml-2" height="32px" @click="croppa.refresh()">Reset</v-btn>
    </div>

    <croppa
      v-if="renderImg"
      v-model="croppa"
      :width="width"
      :height="height"
      :zoom-speed="10"
      placeholder=""
      :disable-drag-and-drop="true"
      :disable-click-to-choose="true"
      :placeholder-font-size="0"
      :disabled="false"
      :show-remove-button="false"
      :initial-image="img"
      :show-loading="true"
      initial-size="contain"
    />
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      default: "",
    },
    height: {
      type: Number,
      default: 100,
    },
    width: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      renderImg: true,
      scale: 1,
      rotate: 0,
      croppa: {},
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
#PreviewImage {
  .toolBox {
    width: max-content;
    position: absolute;
    opacity: 0;
    z-index: 1;
    top: 90px;
    right: 20px;
    transition: 0.25s;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    i {
      padding: 8px;
      border-radius: 50%;
      cursor: pointer;
      color: white;
    }
    i.ZoomIn {
      background: #3dc0cb;
    }
    i.ZoomOut {
      background: #6c6df2;
    }
    i.RotateBack,
    i.RotateNext {
      background: #ffffff;
      color: #3b3c66;
      transition: 0.5s;
    }
    i.RotateBack:active {
      transform: rotate(-360deg);
    }
    i.RotateNext:active {
      transform: rotate(360deg);
    }
    .zoomScale {
      background: white;
      border-radius: 4px;
      padding: 0px 10px;
      margin: 0px 5px;
    }
  }

  &:hover {
    .toolBox {
      opacity: 0;
    }
  }
  .croppa-container {
    background-color: rgb(65, 65, 65);
  }
}
</style>
